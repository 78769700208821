import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  FirebaseStorageService,
  IAffiliateContact,
  UserFinancialProfile,
  UserFinancialProfileStatus,
  UserFinancialProfileTypeTax
} from 'smf-common';
import {FormConfigItem} from './profile-form-config';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FinancialProfileService} from './financial-profile.service';

interface UploadedFile {
  url: string
  name: string
}

export interface FinancialProfileSaveResponse {
  profile: UserFinancialProfile,
  affiliate?: IAffiliateContact
}

@Component({
  selector: 'app-financial-profile',
  templateUrl: './financial-profile.component.html',
  styleUrls: []
})
export class FinancialProfileComponent implements OnInit {

  /**
   *  If the prop is presented and the affiliate is null will try to load the profile from service.
   *  In any case the affiliateID is required in order to edit the affiliate contact information
   */
  @Input() public affiliateId: string
  // Affiliate data
  @Input() public affiliateContact: IAffiliateContact

  // User Financial Profile
  @Input() public profile?: UserFinancialProfile

  // Flag to identify whether profile will be created or updated
  @Input() public createForUserId: string

  // Hide cancel button
  @Input() public hideCancel: boolean

  // Notify parent when request is saved successfully
  @Output() public saved: EventEmitter<FinancialProfileSaveResponse> = new EventEmitter<FinancialProfileSaveResponse>()
  // Notify parent when error occur
  @Output() public error: EventEmitter<Error> = new EventEmitter<Error>()
  // Send close request
  @Output() public close: EventEmitter<any> = new EventEmitter<any>()

  // Form Configuration
  public formConfig: FormConfigItem[] = []
  // Financial Profile Form
  public form: FormGroup
  // Financial Profile Reject Form
  public rejectionForm: FormGroup
  // Uploaded files URL
  public uploadedFiles: UploadedFile[] = []
  // DEFAULT TAX FOR PERSONAL TYPE ACCOUNTS
  public defaultTax = UserFinancialProfileTypeTax.PERSONAL.tax
  // UI Flag
  public inRequest = false
  public isSubmitted = false
  public showRejectionForm = false

  public constructor(private uploader: FirebaseStorageService, private service: FinancialProfileService) {
  }

  public async ngOnInit() {
    if (this.affiliateId && !this.affiliateContact) {
      const affiliate = await this.service.getAffiliateContact(this.affiliateId).toPromise()
      this.affiliateContact = affiliate.contact
    }
    this.form = new FormGroup({
      affiliate: new FormGroup({
        name: new FormControl(this.affiliateContact ? this.affiliateContact.name : '', Validators.required),
        phone: new FormControl(this.affiliateContact ? this.affiliateContact.phone : '', [Validators.pattern(/^(972|0)5[0123458]\d{7}$/), Validators.required])
      }),
      type: new FormControl(this.out('type', undefined, 'PERSONAL'), Validators.required),
      personalInfo: new FormGroup({
        name: new FormControl(this.out('personalInfo', 'name'), Validators.required),
        personalId: new FormControl(this.out('personalInfo', 'personalId'), [Validators.pattern(/^\d{9}$/), Validators.required])
      }),

      businessInfo: new FormGroup({
        name: new FormControl(this.out('businessInfo', 'name'), Validators.required),
        businessNumber: new FormControl(this.out('businessInfo', 'businessNumber'), Validators.required)
      }),

      vat: new FormControl({value: this.out('vat'), disabled: true}, [Validators.pattern(/^\d{1,2}$/), Validators.required]),
      tax: new FormControl(this.out('tax'), [Validators.pattern(/^\d{1,2}$/), Validators.required]),
      dateExpire: new FormControl(this.profile && this.profile.dateExpire ? this.parseDate(this.profile.dateExpire) : '', Validators.required),
    })
    this.onBusinessTypeChange()

    if (this.profile && this.profile.dateExpire) {
      this.generatePathToResource(this.profile._refs.userId, this.profile.attachments.taxInfo.file).then(url => {
        if (url !== undefined) {
          this.uploadedFiles.push({
            url: url,
            name: this.profile.attachments.taxInfo.file
          })
        }
      })
    }

    this.rejectionForm = new FormGroup({
      'invalidReason': new FormControl(this.profile && this.profile.invalidReason ? this.profile.invalidReason : '')
    })
  }

  public onBusinessTypeChange() {
    const type = this.form.value.type
    if (this.profile && this.profile.type !== type) {
      this.form.get('tax').setValue(UserFinancialProfileTypeTax[type].tax)
    } else {
      this.form.get('tax').setValue(this.out('tax', undefined, UserFinancialProfileTypeTax[type].tax))
    }
    if (type === 'PERSONAL') {
      this.form.get('vat').setValue(UserFinancialProfileTypeTax.PERSONAL.vat)
      this.form.get('businessInfo').disable()
      this.form.get('personalInfo').enable()
    } else {
      this.form.get('vat').setValue(UserFinancialProfileTypeTax[type].vat)
      this.form.get('businessInfo').enable()
      this.form.get('personalInfo').disable()
    }
    this.onTaxUpdate()
  }

  public onTaxUpdate() {
    if (this.form.value.type !== 'PERSONAL' || this.form.value.tax.toString() !== this.defaultTax.toString()) {
      this.form.get('dateExpire').enable()
    } else {
      this.form.get('dateExpire').disable()
    }
  }

  private out(k1: string, k2?: string, fallback = '') {
    if (!this.profile) {
      return fallback
    }
    if (k1 && !k2) {
      return this.profile[k1]
    }
    if (!this.profile[k1]) {
      return fallback
    }
    return this.profile[k1][k2]
  }

  private parseDate(value: number | Date | undefined) {
    if (!value) {
      return ''
    }
    if (typeof value === 'number') {
      return new Date(value * 1000)
    } else {
      return value
    }
  }

  private parsePhone(phone: string) {
    if (phone.startsWith('05')) {
      return phone.replace(/^05/, '972')
    }
    return phone
  }

  private changeSubmitFlag() {
    this.isSubmitted = true
  }

  public async onFormSubmit() {
    if (this.inRequest) {
      return
    }
    if (!confirm('Confirm Action')) {
      return
    }
    this.inRequest = true
    if (this.affiliateContact) {
      const saved = await this.updateAffiliateContact().catch(_ => false)
      if (saved === false) {
        this.error.emit(new Error('Contact information failed'))
        this.inRequest = false
        return;
      }
    }
    if (this.createForUserId) {
      this.onProfileCreate()
    } else {
      this.onProfileUpdate()
    }
  }

  public async updateAffiliateContact() {
    const update: IAffiliateContact = this.form.getRawValue().affiliate
    update.phone = this.parsePhone(update.phone)
    if (this.affiliateContact.name === update.name && this.affiliateContact.phone === update.phone) {
      // NO NEED OF UPDATING
      return true
    }
    return await this.service.updateAffiliateContact(this.affiliateId, update).toPromise()
  }

  public processProfileForm(): Partial<UserFinancialProfile> {
    const parseDate = (value: number | Date) => {
      if (typeof value === 'number') {
        return value / 1000
      } else {
        return value.getTime() / 1000
      }
    }
    const profile: Partial<UserFinancialProfile> = this.form.getRawValue()
    const update: Partial<UserFinancialProfile> = {
      type: profile.type,
      status: UserFinancialProfileStatus.VERIFIED,
      vat: UserFinancialProfileTypeTax[profile.type].vat,
      tax: profile.type !== 'PERSONAL' || profile.tax !== this.defaultTax ? parseInt(profile.tax.toString(), 10) : UserFinancialProfileTypeTax[profile.type].tax
    }
    if (profile.type === 'PERSONAL') {
      update.personalInfo = profile.personalInfo
    } else {
      update.businessInfo = profile.businessInfo
    }
    if (profile.type !== 'PERSONAL' || profile.tax.toString() !== this.defaultTax.toString()) {
      update.dateExpire = parseDate(profile.dateExpire)
    }
    return update
  }

  // Will be called only if this.profile.status in not defined
  public onProfileCreate() {
    const success: () => void = this.onProfileUpdateSuccess.bind(this)
    const error: () => void = this.onProfileUpdateError.bind(this)
    const update = this.processProfileForm()
    this.service.createProfile(this.createForUserId, update).subscribe(success, error)
  }

  // Will be called only if this.profile.status is defined
  public onProfileUpdate() {
    const success: () => void = this.onProfileUpdateSuccess.bind(this)
    const error: () => void = this.onProfileUpdateError.bind(this)
    if (this.profile.status !== UserFinancialProfileStatus.VERIFIED && !this.form.dirty) {
      // If the profile status is not verified and the profile is not changed send only status
      this.service.updateProfile(this.profile._id, {status: UserFinancialProfileStatus.VERIFIED}).subscribe(success, error)
    } else {
      const update = this.processProfileForm()
      this.service.updateProfile(this.profile._id, update).subscribe(success, error)
    }
  }

  public onProfileUpdateSuccess() {
    this.inRequest = false
    const profile = {...this.profile, ...this.form.getRawValue()}
    profile.status = UserFinancialProfileStatus.VERIFIED

    let affiliate: IAffiliateContact
    if (this.affiliateContact) {
      affiliate = this.form.getRawValue().affiliate
      affiliate.phone = this.parsePhone(affiliate.phone)
    }

    this.saved.emit({
      profile: profile,
      affiliate: affiliate
    })
  }

  public onProfileRejectSuccess() {
    this.inRequest = false
    const data = this.rejectionForm.getRawValue()
    const profile = {...this.profile}
    profile.status = UserFinancialProfileStatus.INVALID
    profile.invalidReason = data.invalidReason
    this.saved.emit({
      profile: profile,
      affiliate: this.affiliateContact
    })
  }

  public onProfileUpdateError(error: Error) {
    this.inRequest = false
    this.error.emit(error)
  }

  public onClose() {
    this.close.emit(true)
  }

  public async generatePathToResource(userId: string, filename: string): Promise<string | undefined> {
    return await this.uploader.getFileURL(`/user/${userId}/fin/${filename}`).catch(e => {
      console.log(e);
      return undefined
    })
  }

  // MARK: REJECTION FORM START
  public statusToBadge() {
    const map = {NEW: 'badge-info', PENDING: 'badge-warning', VERIFIED: 'badge-success', INVALID: 'badge-danger'}
    const key = this.profile ? this.profile.status : 'NEW'
    if (map[key]) {
      return map[key]
    }
    return map.NEW
  }

  public toggleRejectReason() {
    this.showRejectionForm = !this.showRejectionForm
    console.log(this.showRejectionForm);
  }

  public onProfileReject() {
    if (this.inRequest) {
      return
    }
    if (!confirm('Confirm Action')) {
      return
    }
    this.inRequest = true
    const data = this.rejectionForm.getRawValue()
    const success: () => void = this.onProfileRejectSuccess.bind(this)
    const error: () => void = this.onProfileUpdateError.bind(this)
    this.service.updateProfile(this.profile._id, {
      status: UserFinancialProfileStatus.INVALID,
      invalidReason: data.invalidReason
    }).subscribe(success, error)
  }

}
