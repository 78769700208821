import {MenuItem} from '../core/menu/menu-item'
import {RolePermission, SmartyRoles} from 'smf-common'

const Dev: MenuItem = {
  text: 'Dev Console',
  link: '/dev-console',
  icon: 'fa fa-terminal',
  permission: new RolePermission([
    SmartyRoles.admin.valueOf()
  ])
}

const UserManagement: MenuItem = {
  text: 'Users',
  icon: 'fas fa-user-cog',
  permission: new RolePermission([
    SmartyRoles.admin_users.valueOf(),
    SmartyRoles.admin_users_blacklist.valueOf()
  ]),
  submenu: [
    {
      text: 'Management',
      link: '/admin/users',
      permission: new RolePermission([
        SmartyRoles.admin_users.valueOf()
      ]),
    }, {
      text: 'Blacklist',
      link: '/admin/blacklist',
      permission: new RolePermission([
        SmartyRoles.admin_users.valueOf(),
        SmartyRoles.admin_users_blacklist.valueOf()
      ]),
    },
  ]
}

const SurveyBuilder: MenuItem = {
  text: 'Project Management',
  icon: 'fa fa-list',
  permission: new RolePermission([
    SmartyRoles.adminfinder.valueOf(),
    SmartyRoles.admin_projects.valueOf(),
    SmartyRoles.admin_import.valueOf()
  ]),
  submenu: [
    {
      text: 'Questionnaires',
      link: '/survey/management',
      permission: new RolePermission([
        SmartyRoles.adminfinder.valueOf(),
        SmartyRoles.admin_projects.valueOf()
      ]),
    },
    {
      text: 'Projects',
      link: '/admin/projects',
      permission: new RolePermission([
        SmartyRoles.adminfinder.valueOf(),
        SmartyRoles.admin_projects.valueOf()
      ]),
    },
    {
      text: 'Import Leads',
      link: '/admin/import-leads',
      permission: new RolePermission([
        SmartyRoles.adminfinder.valueOf(),
        SmartyRoles.admin_import.valueOf()
      ])
    },
    {
      text: 'Revert Deals',
      link: '/admin/deals',
      permission: new RolePermission([
        SmartyRoles.adminfinder.valueOf(),
        SmartyRoles.admin_import.valueOf()
      ])
    },
    {
      text: 'Projects Stats',
      link: '/admin/projects-stats',
      permission: new RolePermission([
        SmartyRoles.adminfinder.valueOf(),
        SmartyRoles.admin_projects.valueOf(),
        SmartyRoles.admin.valueOf()
      ])
    }
  ]
}

const Affiliates: MenuItem = {
  text: 'Affiliates',
  link: '/affiliates',
  icon: 'fa fa-users',
  permission: new RolePermission([
    SmartyRoles.admin_affiliates.valueOf(),
    SmartyRoles.admin_pages.valueOf(),
    SmartyRoles.admin_socials.valueOf(),
    SmartyRoles.admin_assignments.valueOf(),
    SmartyRoles.admin_users.valueOf()
  ]),
  submenu: [
    {
      text: 'Affiliate Social',
      link: '/admin/social',
      permission: new RolePermission([SmartyRoles.admin_affiliates.valueOf(), SmartyRoles.admin_socials.valueOf()])
    },
    {
      text: 'Personal Pages',
      link: '/admin/personalpage',
      permission: new RolePermission([SmartyRoles.admin_affiliates.valueOf(), SmartyRoles.admin_pages.valueOf()])
    },
    {
      text: 'FB Requests',
      permission: new RolePermission([
        SmartyRoles.admin_assignments.valueOf()
      ]),
      link: '/admin/requests'
    },

    // New Affiliates
    {
      text: 'Upgrades',
      link: '/agents/upgrades',
      permission: new RolePermission([
        SmartyRoles.adminfinder.valueOf()
      ])
    },
    {
      text: 'Not Positioned',
      link: '/agents/notpositioned',
      permission: new RolePermission([
        SmartyRoles.adminfinder.valueOf()
      ])
    },

    {
      text: 'Slots',
      link: '/agents/slots',
      permission: new RolePermission([
        SmartyRoles.adminfinder.valueOf()
      ])
    },

    // Financial Profile
    // {
    //   text: 'Financial Profile OLD',
    //   link: '/financial-profile/affiliates',
    //   permission: new RolePermission([
    //     SmartyRoles.admin_billing.valueOf()
    //   ]),
    // }
  ]

}

const Billing: MenuItem = {
  text: 'Billing',
  icon: 'fas fa-file-invoice-dollar',
  permission: new RolePermission([
    SmartyRoles.admin_billing.valueOf(),
    SmartyRoles.admin_billing_bonuses.valueOf()
  ]),
  submenu: [
    {
      text: 'Periods',
      link: '/billing/supplier',
      permission: new RolePermission([
        SmartyRoles.admin_billing.valueOf()
      ])
    },
    {
      text: 'Bills',
      link: '/billing/bills',
      permission: new RolePermission([
        SmartyRoles.admin_billing.valueOf()
      ])
    },
    {
      text: 'Affiliate Bonuses',
      link: '/billing/affiliate',
      permission: new RolePermission([
        SmartyRoles.admin_billing_bonuses.valueOf()
      ])
    },
    {
      text: 'Withdraw Requests',
      link: '/billing/requests',
      permission: new RolePermission([
        SmartyRoles.admin_billing_bonuses.valueOf()
      ])
    }
  ]
}

const CRM: MenuItem = {
  text: 'CRM',
  icon: 'fas fa-hands-helping',
  permission: new RolePermission([
    SmartyRoles.admin.valueOf(),
    SmartyRoles.admin_reports.valueOf()
  ], undefined, undefined, 'finder-tdbm'),
  submenu: [
    {
      text: 'Purchase Orders',
      link: '/tdf/crm/orders',
      permission: new RolePermission([
        SmartyRoles.admin.valueOf()
      ], undefined, undefined, 'finder-tdbm')
    },
    {
      text: 'Market Products',
      link: '/tdf/crm/periods/products',
      permission: new RolePermission([
        SmartyRoles.admin.valueOf()
      ], undefined, undefined, 'finder-tdbm')
    },
    {
      text: 'Slots Transfers',
      link: '/tdf/crm/slots-transfers',
      permission: new RolePermission([
        SmartyRoles.admin.valueOf()
      ], undefined, undefined, 'finder-tdbm')
    },
    {
      text: 'Period Settings',
      link: '/tdf/crm/periods/settings',
      permission: new RolePermission([
        SmartyRoles.admin.valueOf()
      ], undefined, undefined, 'finder-tdbm')
    },
    {
      text: 'Report Review',
      link: '/tdf/crm/business-admin/report-review',
      permission: new RolePermission([
        SmartyRoles.admin.valueOf(),
        SmartyRoles.admin_reports.valueOf()
      ], undefined, undefined, 'finder-tdbm')
    },
    {
      text: 'Users Slots Report',
      link: '/tdf/crm/reports/users-slots-report',
      permission: new RolePermission([
        SmartyRoles.admin.valueOf(),
        SmartyRoles.admin_reports.valueOf()
      ], undefined, undefined, 'finder-tdbm')
    },
    {
      text: 'Products Availability Report',
      link: '/tdf/crm/reports/products-availability-report',
      permission: new RolePermission([
        SmartyRoles.admin.valueOf(),
        SmartyRoles.admin_reports.valueOf()
      ], undefined, undefined, 'finder-tdbm')
    },
    {
      text: 'Users Orders Report',
      link: '/tdf/crm/reports/users-orders-report',
      permission: new RolePermission([
        SmartyRoles.admin.valueOf(),
        SmartyRoles.admin_reports.valueOf()
      ], undefined, undefined, 'finder-tdbm')
    }
  ],
}

const Translate: MenuItem = {
  text: 'Translate',
  icon: 'fas fa-language',
  submenu: [{
    text: 'Guides',
    link: '/translate/guides'
  }]
}

const FinderellaLeads: MenuItem = {
  text: 'Finderella Leads',
  icon: 'fas fa-th-list',
  permission: new RolePermission([
    SmartyRoles.admin_leads.valueOf()
  ]),
  submenu: [{
    text: 'Missed',
    link: '/finderella-leads/missed',
    permission: new RolePermission([
      SmartyRoles.admin_leads.valueOf()
    ]),
  }]
}

const MobileApplications: MenuItem = {
  text: 'Mobile Applications',
  icon: 'fas fa-mobile-alt',
  permission: new RolePermission([
    SmartyRoles.admin_mobile_apps.valueOf()
  ]),
  submenu: [{
    text: 'Versions',
    link: '/mobile-applications/versions',
    permission: new RolePermission([
      SmartyRoles.admin_mobile_apps.valueOf()
    ]),
  }]
}

const Reports: MenuItem = {
  text: 'Reports',
  icon: 'fas fa-file-excel',
  permission: new RolePermission([
    SmartyRoles.adminfinder.valueOf(), SmartyRoles.admin_reports.valueOf()
  ]),
  submenu: [
    {
      text: 'Affiliates',
      link: '/reports/affiliates',
      permission: new RolePermission([
        SmartyRoles.adminfinder.valueOf(), SmartyRoles.admin_reports.valueOf()
      ]),
    },
    {
      text: 'Billing',
      link: '/reports/billing',
      permission: new RolePermission([
        SmartyRoles.adminfinder.valueOf(), SmartyRoles.admin_reports.valueOf()
      ]),
    },
    {
      text: 'Withdraws',
      link: '/reports/withdraws',
      permission: new RolePermission([
        SmartyRoles.adminfinder.valueOf(), SmartyRoles.admin_reports.valueOf()
      ]),
    },
    {
      text: 'Leads',
      link: '/reports/leads',
      permission: new RolePermission([
        SmartyRoles.adminfinder.valueOf(), SmartyRoles.admin_reports.valueOf()
      ]),
    }
  ]
}

const Periods: MenuItem = {
  text: 'Periods',
  link: '/periods',
  icon: 'fa fa-calendar',
  permission: new RolePermission([
    SmartyRoles.admin.valueOf()
  ])
}

const headingMain: MenuItem = {
  text: 'Main Navigation',
  heading: true
}

export const menu: Array<MenuItem> = [
  // headingMain,
  Dev,
  UserManagement,
  Affiliates,
  SurveyBuilder,
  Billing,
  Periods,
  CRM,
  FinderellaLeads,
  MobileApplications,
  Reports
  // Translate
]
