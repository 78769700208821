import {Component, Inject, OnInit} from '@angular/core';
import {SettingsService} from '../../core/settings/settings.service';
import {I18NEXT_SERVICE, ITranslationService} from 'angular-i18next';

@Component({
  selector: '[app-footer]',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public language: string;
  public languages: string[];

  private availablelangs = {
    'ro': 'Română',
    'es': 'Español',
    'nl': 'Dutch',
    'en': 'English',
    'bg': 'Български',
    'he': 'עברית',
    'ar': 'عربى'
  };

  public constructor(public settings: SettingsService, @Inject(I18NEXT_SERVICE) private i18NextService: ITranslationService) {
    this.language = i18NextService.language;
    this.languages = [...this.i18NextService.options.whitelist];
    this.languages.splice(this.languages.indexOf('cimode'), 1);
  }

  public ngOnInit() {
  }

  public changeLang() {
    this.i18NextService.changeLanguage(this.language).then(e => {
      /// Reload to refresh phrases
      location.reload();
    });
  }

  public codeToLanguage(langCode: string): string {
    return this.availablelangs[langCode] ? this.availablelangs[langCode] : langCode;
  }

}
