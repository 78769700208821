import {Title} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'; // this is needed!
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';

import {AppComponent} from './app.component';

import {CoreModule} from './core/core.module';
import {LayoutModule} from './layout/layout.module';
import {SharedModule} from './shared/shared.module';
import {RoutesModule} from './routes/routes.module';
import {defaultInterpolationFormat, I18NEXT_SERVICE, I18NextModule, ITranslationService} from 'angular-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';
import {environment} from '../environments/environment';
import {authInitializer, AuthModule, FirebaseStorageService, KeycloakService} from 'smf-common';

const i18nextOptions = {
  whitelist: ['en', 'he'],
  fallbackLng: 'en',
  interpolation: {
    format: I18NextModule.interpolationFormat(defaultInterpolationFormat),
    prefix: '{',
    suffix: '}'
  },
  backend: {
    allowMultiLoading: false,
    crossDomain: true,
    loadPath: function () {
      return environment.translationsUrl;
    },
    parse: function (data: string) {
      return Object.values(JSON.parse(data))[0]
    },
    customHeaders: {
      'Content-Type': 'application/json',
      'X-API-Key': 'tgpak_gezv63ttn5yta2btouzgi2zymrzxg5tbmjtg24lonjyxeoa'
    }
  },
  detection: {
    order: ['localStorage', 'navigator'],
    lookupLocalStorage: 'lang',
    caches: ['localStorage']
  }
};

export function appInit(i18next: ITranslationService) {
  return () => i18next
    .use(XHR)
    .use(LanguageDetector)
    .init(i18nextOptions);
}

export function localeIdFactory(i18next: ITranslationService) {
  return i18next.language;
}

export function preAuthInitializer( keycloak: KeycloakService ) {
  return authInitializer(keycloak, environment);
}

export function firebaseInit(firebase: FirebaseStorageService) {
  return () => {
    return firebase.init({
      bucket: 'gs://smf-affiliates',
      apiKey: 'AIzaSyCuZgWzoNqTWMpaQDEojPW8zSS2WI9WVk8',
      tokenApi: environment.apiHost + '/api/firebase/admin-token'
    })
  }
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    HttpClientModule,
    AuthModule.forRoot(),
    BrowserAnimationsModule, // required for ng2-tag-input
    CoreModule,
    LayoutModule,
    SharedModule.forRoot(),
    RoutesModule,
    I18NextModule.forRoot({})
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: firebaseInit,
      deps: [FirebaseStorageService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: preAuthInitializer,
      multi: true,
      deps: [KeycloakService]
    }, {
      provide: APP_INITIALIZER,
      useFactory: appInit,
      deps: [I18NEXT_SERVICE],
      multi: true
    },
    Title
    // see https://next.angular.io/guide/i18n#i18n-pipes
    // {
    //   provide: LOCALE_ID,
    //   deps: [I18NEXT_SERVICE],
    //   useFactory: localeIdFactory
    // }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
