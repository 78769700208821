import {LayoutComponent} from '../layout/layout.component';

export const routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {path: '', redirectTo: 'admin', pathMatch: 'full'},
      {path: 'dev-console', loadChildren: './dev-console/dev-console.module#DevConsoleModule'},
      {path: 'survey', loadChildren: './survey-builder/survey-builder.module#SurveyBuilderModule'},
      {path: 'admin', loadChildren: './admin/admin.module#AdminModule'},
      {path: 'billing', loadChildren: './billing/billing.module#BillingModule'},
      {path: 'agents', loadChildren: './agents-upgrades/agents-upgrades.module#AgentsUpgradesModule'},
      {path: 'translate', loadChildren: './translate/translate.module#TranslatesModule'},
      {path: 'financial-profile', loadChildren: './financial-profiles/financial-profiles.module#FinancialProfilesModule'},
      {path: 'tdf/crm/orders', loadChildren: './finderella/crm/order-management/order-management.module#OrderManagementModule'},
      {path: 'tdf/crm/periods', loadChildren: './finderella/crm/period-management/period-management.module#PeriodManagementModule'},
      {path: 'tdf/crm/business-admin', loadChildren: './finderella/crm/business-admin/business-admin.module#BusinessAdminModule'},
      {path: 'tdf/crm/reports', loadChildren: './finderella/crm/reports/crm-reports.module#CrmReportsModule'},
      {path: 'tdf/crm', loadChildren: './finderella/crm/crm.module#CrmModule'},
      {path: 'finderella-leads/missed', loadChildren: './finderella/missed-leads/missed-leads.module#MissedLeadsModule'},
      {path: 'mobile-applications/versions', loadChildren: './mobile-applications/mobile-applications.module#MobileApplicationsModule'},
      {path: 'reports', loadChildren: './reports/reports.module#ReportsModule'},
      {path: 'periods', loadChildren: './periods/periods.module#PeriodsModule'},
    ]
  },
  // Not found
  {path: '**', redirectTo: ''}
];
