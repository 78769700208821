import {Component, OnInit} from '@angular/core';

import {UserblockService} from './userblock.service';
import {User} from "smf-common";

@Component({
  selector: 'app-userblock',
  templateUrl: './userblock.component.html',
  styleUrls: ['./userblock.component.scss']
})
export class UserblockComponent implements OnInit {
  public user: User;

  constructor(public userblockService: UserblockService) {

  }

  ngOnInit() {
    this.userblockService.getUser().subscribe(u => this.user = u);
  }

  userBlockIsVisible() {
    return this.userblockService.getVisibility();
  }

  isUserLoggedIn() {
    return this.user !== null;
  }

  login() {
    this.userblockService.login();
  }
}
