import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'environments/environment';
import {IAffiliate, IAffiliateContact, UserFinancialProfile} from 'smf-common';

@Injectable({
  providedIn: 'root'
})
export class FinancialProfileService {

  public path = environment.apiHost + '/api/admin/financial-profiles'

  public constructor(private http: HttpClient) {
  }

  public updateProfile(affiliateId: string, data: Partial<UserFinancialProfile>) {
    return this.http.patch(`${this.path}/${affiliateId}`, data)
  }

  public createProfile(userId: string, data: Partial<UserFinancialProfile>) {
    return this.http.post(this.path + `/affiliates/${userId}`, data)
  }

  public updateAffiliateContact(affiliateId: string, data: IAffiliateContact) {
    const url = `${environment.apiHost}/api/admin/affiliates/${affiliateId}/contact`
    return this.http.patch(url, data)
  }

  public getAffiliateContact(affiliateId: string) {
    const url = `${environment.apiHost}/api/admin/affiliates/${affiliateId}`
    return this.http.get<IAffiliate>(url)
  }
}
